body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background: rgb(27, 27, 27);
    color: rgb(236, 236, 236);
}

body::-webkit-scrollbar {
    width: 10px;
}

body::-webkit-scrollbar-track {
    background-color: rgb(32, 32, 32);
}

body::-webkit-scrollbar-thumb {
    background-color: rgb(73, 73, 73);
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.button {
    display: inline-block;
    height: 38px;
    padding: 5px 30px;
    background-color: transparent;
    color: rgb(248, 248, 248);
    text-align: center;
    font-size: 11px;
    font-weight: 600;
    letter-spacing: 0.1rem;
    text-transform: uppercase;
    text-decoration: none;
    white-space: nowrap;
    border-radius: 5px;
    cursor: pointer;
    box-sizing: border-box;
    transition: all 0.15s;
    outline: none;
    border: solid 2px #252525;
}

.button:hover {
    box-shadow: 0px 4px 15px 0px #00000061;
}

.button:active {
    transform: translateY(-2px);
}

.primary-button {
    background-color: #f7f7f7;
    color: #141414;
}

.formInput {
    border-radius: 5px 5px 2px 2px;
    width: 100%;
    padding: 10px 15px;
    border-bottom: 2px solid gray;
    color: white;
    background: #242424;
    transition: all 0.15s;
    outline: none;
    border: solid 2px #252525;
}

.formInput:hover {
    border: solid 2px #4b4b4b;
}

.formInput:active {
    border: solid 2px #585858;
}

.formInput:focus {
    outline: none;
    border: solid 2px #4b4b4b;
}

.errorMessage {
    color: #f44336;
    margin-top: 1em;
    max-width: 400px;
}