.MessageBox {
    min-height: 90px;
    height: fit-content;
    display: flex;
    position: relative;
    padding: 0.5em 1em;
    justify-content: space-around;
    align-items: flex-start;
    transition: all 0.15s;
    border-bottom: solid 2px #4e4e4e;
    &:hover {
        &:before {
            width: 5px;
        }
    }
    &:before {
        width: 0px;
        content: "\A";
        background: #4e4e4e;
        height: 100%;
        z-index: 10;
        position: absolute;
        left: 0;
        top: 0;
        transition: all 0.1s ease-in;
    }
    .avatar {
        height: 50px;
        width: 50px;
        border-radius: 50px;
        // background: #2196f3;
        text-align: center;
        line-height: 50px;
        font-weight: bold;
        font-size: 1.5em;
        margin: 1em 0.5em 1em 0em;
        transition: all 0.15s;
        user-select: none;
    }
    .details {
        width: 80%;
        .detailsHeader {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
    }
    .dateTime {
        // float: right;
        color: #a8a8a8;
        user-select: none;
    }
}

.senderEmail {
    font-style: italic;
    color: #c0c0c0;
    display: block;
    padding: 2px 0px;
    width: fit-content;
}

.unread {
    background: rgb(60, 60, 60);
    &:before {
        background: #7e7e7e;
    }
}

.read {}

.turnCateBtn {
    margin-top: 10px;
    float: right;
}

.icon {
    background-color: rgb(226, 226, 226);
    border-radius: 25px;
}

.expandIcon {
    @extend .icon;
    transition: all 0.15s ease-in;
}

.expandMore {
    &:hover {
        transform: translateY(10%);
    }
}

.expandLess {
    &:hover {
        transform: translateY(-10%);
    }
}