#loginPage {
    position: relative;
    margin: auto;
    margin-top: 3vh;
    max-width: 900px;
    padding: 1em 2em;
    .pageTitle {
        display: flex;
        align-items: center;
        margin: 0;
        h1 {
            margin-left: 0.8em;
        }
    }
}

.formLabel {
    display: block;
    max-width: 40vw;
    margin-top: 2em;
    font-size: 1.2em;
}

.securityKeyBlock {
    display: block;
    width: fit-content;
    padding: 5px 10px;
    border-radius: 5px;
    font-weight: bold;
    background-color: #ffffff17;
    color: #ffffff;
}

#keyInput {
    max-width: 500px;
    display: block;
}

#unlockBtn {
    margin-top: 2em;
    background-color: #e7ae01;
    color: white;
}

.loginPageIcon {
    padding: 0.5em;
    vertical-align: middle;
}

#signInBtn {
    margin-top: 2em;
    cursor: pointer;
}

.iconInput {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 1em;
    .inputIconBox {
        background: #242424;
        // height: 10px;
        border-radius: 2px;
        border: solid 2px rgb(66, 66, 66);
        padding: 6px 8px;
    }
    input {
        margin: 0;
        color: #ffeb3b;
    }
}

.gifImage {
    width: 100%;
    max-width: 500px;
    margin-top: 1em;
    img {
        width: 100%;
        height: auto;
    }
}

.keyGif {
    max-width: 300px;
}