main {
    position: relative;
    margin: auto;
    margin-top: 5em;
    max-width: 900px;
    .pageTitle {
        margin-left: 0.5em;
    }
    .titleBar {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0em 1em;
    }
}

.messageList {
    margin: 6em 0;
}

#signOutBtn {
    // position: absolute;
    // right: 1em;
    // top: 0;
    background-color: #f44336;
    color: white;
}